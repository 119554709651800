<template>
    <div class="Banner animate__animated animate__fadeOutDown">
        <img src="../assets/img/imgStaly1/banner.jpg" alt="">
    </div>
  </template>
  
  <script>
  export default {
    name: "Banner",
    data() {
      return {
      };
    },
    methods: { 
        
    },
    mounted() {
        setTimeout(function() {
            window.location.href = 'https://zhxzadmin.xueyoyo.com/'
        },2000)
    },
  };
  </script>
  <style scoped>
  
  </style>
  